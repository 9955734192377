import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { amountToCurrency } from "@/utils/formatAmount";
import { formatDate } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useInvoicesList() {
  // Use toast
  const toast = useToast();

  const refInvoiceListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "facture_no", label: "#", sortable: true },
    { key: "statut", sortable: true },
    { key: "client", label: "Client", sortable: true, formatter: (val) => val.name },
    { key: "net_payable", label: "Net À Payer", sortable: true, formatter: (val) => (amountToCurrency(val, 'fr-FR', null) + " FCFA") },
    { key: "issued_date", label: "Date Émission", sortable: true, formatter: (val) => formatDate(val, { month: "short", day: "numeric" }) },
    { key: "total_paid", label: "Total Payé", sortable: true, formatter: (val) => (amountToCurrency(val, 'fr-FR', null) + " FCFA") },
    { key: "actions" },
  ];
  const isLoading = ref(false);
  const perPage = ref(10);
  const totalInvoices = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("created_at");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    };
  });

  const fetchInvoiceProperties = async (params) => {
    await store.dispatch("invoices/fetchProperties", {
      props: [...params],
    });
  };

  const fetchClientProjects = async (clientID) => {
    isLoading.value = true;
    await store.dispatch("invoices/fetchClientProjects", {
      client_id: clientID,
    });
    isLoading.value = false;
  };

  const fetchProjectBudget = async (projectUID) => {
    store.commit('invoices/SET_BUDGET', {})
    store.commit('invoices/SET_AVAILABLE_SERVICES', [])
    try {
      const { data, success } = await store.dispatch("invoices/fetchProjectBudget", {
        project_uid: projectUID,
      });
      if(!success) {
        toast({
          component: ToastificationContent,
          props: {
            title: data?.error,
            icon: "AlertCircleIcon",
            variant: "warning",
          },
        });
      }
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Une erreur est survenue lors de la récupération du budget",
          icon: "AlertCircleIcon",
          variant: "danger",
        },
      });
    }
  };

  const refetchData = () => {
    refInvoiceListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData();
  });

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch("invoices/list", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then((response) => {
        const { invoices, total } = response;

        callback(invoices);
        totalInvoices.value = total;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur de récupération des factures",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = (invoice) => {
    if (!invoice.is_paid && invoice.total_paid > 0)
      return { variant: "warning", icon: "PieChartIcon" };
    if (invoice.is_paid)
      return { variant: "success", icon: "CheckCircleIcon" };
    // if (invoice.is_paid === "Télécharger")
    //   return { variant: "info", icon: "ArrowDownCircleIcon" };
    // if (status === "Brouillon") return { variant: "primary", icon: "SaveIcon" };
    // if (status === "Envoyé") return { variant: "secondary", icon: "SendIcon" };
    if (invoice.is_late) return { variant: "danger", icon: "InfoIcon" };
    return { variant: "secondary", icon: "XIcon" };
  };

  const resolveClientAvatarVariant = (status) => {
    if (status === "Partiellement payé") return "primary";
    if (status === "Payé") return "danger";
    if (status === "Télécharger") return "secondary";
    if (status === "Brouillon") return "warning";
    if (status === "Envoyé") return "info";
    if (status === "En Retard") return "success";
    return "primary";
  };

  return {
    fetchInvoices,
    fetchInvoiceProperties,
    fetchClientProjects,
    fetchProjectBudget,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,
    formatDate,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
    isLoading,
  };
}
